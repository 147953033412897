

































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import PatientForm from '@/components/patient/PatientForm.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogPatientCreate',
  components: {
    CompositeDialog,
    AlertError,
    PatientForm
  },
  setup() {
    const dialogId: DialogId = 'patient-create';
    const rDialog = ref<any>(null);

    const { trackSuccess, trackFailure } = useAnalytics();

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    const currentUnit = useUnit().currentUnit();

    const patientCreateRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/patients?unit=${currentUnit.current.value}`,
      {
        axios: {
          method: 'POST'
        }
      }
    );

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (!value) {
            patientCreateRequest.cancel();

            nextTick(() => {
              patientCreateRequest.clear();
            });
          }
        }
      );
    });

    function submitForm() {
      rDialog.value.$children[0].$children[0].$children[0].$children[1].submit();
    }

    function createPatient(infos: any) {
      patientCreateRequest.request({
        axios: {
          method: 'POST',
          data: {
            infos
          }
        }
      });
    }

    function closeDialog() {
      rDialog.value.close();
    }

    const isDisabled = computed(() => {
      return patientCreateRequest.isPending.value;
    });

    const isSuccess = computed(() => {
      return !patientCreateRequest.error.value && !!patientCreateRequest.data.value;
    });

    const isError = computed(() => {
      return patientCreateRequest.error.value;
    });

    watch(isSuccess, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.patientCreated({
            patientCuid: patientCreateRequest.data.value.cuid
          })
        );
        trackSuccess('PatientSC/New Patient/Save', {
          pathology: patientCreateRequest.data.value.infos.pathologies,
          height: patientCreateRequest.data.value.infos.height,
          weight: patientCreateRequest.data.value.infos.weight,
          'shoe-size': patientCreateRequest.data.value.infos.footSize,
          country: patientCreateRequest.data.value.infos.address.country,
          city: patientCreateRequest.data.value.infos.address.city
        });

        closeDialog();
      }
    });
    watch(isError, (value) => {
      if (value) trackFailure('Delete Patient', 'Patient creation failed');
    });

    return {
      // References
      rDialog,
      // Values
      dialogId,
      patientCreateRequest,
      // Methods
      submitForm,
      createPatient,
      closeDialog,
      // Flags
      isDisabled,
      isSuccess
    };
  }
});
