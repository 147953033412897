























































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, onBeforeUnmount, watch } from '@vue/composition-api';
import { capitalize, get } from 'lodash';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
import { useDataTable } from '@/utils/data-table.utils';
import { useCDN } from '@/utils/cdn.utils';
import { useAnalytics } from '@/utils/analytics.utils';
import { useRehab } from '@/utils/rehab.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
import DialogPatientUpdate from '@/components/dialogs/DialogPatientUpdate.vue';
// Import types ------------------------------------------------------------------------------------
import type { DataTableHeader } from 'vuetify';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientsList',
  components: { AlertError, DialogPatientUpdate },
  setup(_, { root }) {
    const { cdn } = useCDN();
    const { getMode } = useRehab();

    const requestModule = usePodocoreModule('request');

    const fetchPatients = requestModule.useAuthenticatedRequest(`${apiConfig.default}/patients`);

    const busModule = usePodocoreModule('bus');

    const workspacesService = usePodocoreModuleService('workspaces');

    const { trackSuccess } = useAnalytics();

    const {
      options,
      search,
      hasBeenLoadedAtLeastOnce,
      sanitizedData,
      updateOptions,
      isDelayedPending,
      request
    } = useDataTable(
      fetchPatients,
      {
        itemsPerPage: 10,
        sortBy: ['infos.lastName'],
        sortDesc: [true]
      },
      true
    );

    const { error, cancel } = fetchPatients;

    watch(workspacesService.state, (currentState) => {
      if (currentState.matches('success')) {
        request();
      }
    });

    onBeforeUnmount(() => {
      cancel();
    });

    const headers = computed((): DataTableHeader[] => [
      {
        text: String(root.$t('commons.standards.last-name')),
        value: 'infos.lastName',
        width: 180
      },
      {
        text: String(root.$t('commons.standards.first-name')),
        value: 'infos.firstName',
        width: 180
      },
      {
        text: String(root.$t('commons.standards.birth-date')),
        value: 'infos.birthDate',
        width: 200
      },
      {
        text: String(root.$t('commons.standards.foot-size')),
        value: 'infos.footSize',
        width: 140
      },
      {
        text: String(root.$t('commons.standards.latest-acquisition')),
        value: '_latestAnalysis',
        sortable: false
      },
      {
        text: String(root.$tc('commons.standards.action', 2)),
        value: '__actions',
        sortable: false,
        width: 90,
        align: 'center'
      }
    ]);

    function openPatient(patient: any) {
      root.$router.push({ path: `/patients/${patient.cuid}#dashboard` });
    }

    function createPatient() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'patient-create'
        })
      );
    }

    function editPatient(patientCuid: string) {
      busModule.publish(
        busModule.events.openDialog({
          id: 'patient-update',
          meta: { patientCuid }
        })
      );
    }

    function deletePatient(patientCuid: string) {
      busModule.publish(
        busModule.events.openDialog({
          id: 'patient-delete',
          meta: { patientCuid }
        })
      );
    }

    busModule.useEventSubscriber(busModule.events.patientCreated, ({ payload }) => {
      root.$router.push({ path: `/patients/${payload.patientCuid}` });
    });
    busModule.useEventSubscriber(busModule.events.patientPatched, () => {
      request();
    });
    busModule.useEventSubscriber(busModule.events.patientDeleted, () => {
      request();
    });

    function checkIfTextHighlighted(item: any, path: string) {
      const h = item._searchHighlights?.find((_h: any) => _h.path === path);
      return !!h;
    }

    return {
      // Utils
      cdn,
      trackSuccess,
      // Values
      search,
      headers,
      sanitizedData,
      error,
      options,
      // Methods
      updateOptions,
      capitalize,
      openPatient,
      createPatient,
      editPatient,
      deletePatient,
      getMode,
      checkIfTextHighlighted,
      // Flags
      isDelayedPending,
      hasBeenLoadedAtLeastOnce
    };
  }
});
