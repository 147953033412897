import { computed } from '@vue/composition-api';
import { useChart } from '@/utils/charts.utils';
import i18n from '@/plugins/i18n';

export type TRehabMode = 'triple-hop' | 'CMJ' | 'single-hop' | 'side-hop';

export function useRehab(): {
  getFormattedData: (value: string) => string;
  getMode: (scenarioKey: string) => TRehabMode;
  getActiveWidgets: (mode: TRehabMode) => string[];
  getSimplifiedUnit: (unit: string) => string;
  getTripleHopJumpProfileData: (aggregates: any, segments: any) => any;
  getTripleHopGeneralParametersSimplifiedData: (aggregates: any) => any;
  getTripleHopGeneralParametersAdvancedData: (aggregates: any, segments: any) => any;
  getCMJGeneralParametersSimplifiedData: (aggregates: any) => any;
  getCMJGeneralParametersAdvancedData: (aggregates: any) => any;
  getSingleHopGeneralParametersSimplifiedData: (aggregates: any) => any;
  getSingleHopGeneralParametersAdvancedData: (aggregates: any) => any;
  getSideHopGeneralParametersSimplifiedData: (aggregates: any) => any;
  getSideHopGeneralParametersAdvancedData: (aggregates: any) => any;
  getSideHopCadenceEvolutionChartOptions: (segments: any) => any;
} {
  const getSimplifiedUnit = (unit: string) => {
    if (unit === 'deg') {
      return '°';
    }
    if (unit === 'deg/s') {
      return '°/s';
    }
    return unit;
  };

  const getMode = (scenarioKey: string) => {
    if (scenarioKey.includes('triple_hop')) {
      return 'triple-hop';
    }
    if (scenarioKey.includes('CMJ')) {
      return 'CMJ';
    }
    if (scenarioKey.includes('single_hop')) {
      return 'single-hop';
    }
    return 'side-hop';
  };

  const getFormattedData = (value: string) => {
    if (!value) return '- ';
    return value;
  };

  const getTripleHopJumpProfileData = (aggregates: any, segments: any) => {
    return {
      leftContactTime: `${getFormattedData(aggregates.jumping_stance_time.left_foot.avg)} ${getSimplifiedUnit(
        aggregates.jumping_stance_time.unit
      )}`,
      leftFlightTime: `${getFormattedData(aggregates.jumping_flight_time.left_foot.avg)} ${getSimplifiedUnit(
        aggregates.jumping_flight_time.unit
      )}`,
      rightContactTime: `${getFormattedData(
        aggregates.jumping_stance_time.right_foot.avg
      )} ${getSimplifiedUnit(aggregates.jumping_stance_time.unit)}`,
      rightFlightTime: `${getFormattedData(
        aggregates.jumping_flight_time.right_foot.avg
      )} ${getSimplifiedUnit(aggregates.jumping_flight_time.unit)}`,
      jumpHeight: `${getFormattedData(
        aggregates.jumping_stride_length.global.avg_diff_R_L
      )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
      totalTime: `${getFormattedData(aggregates.jumping_flight_time.global.avg_diff_R_L)}${getSimplifiedUnit(
        aggregates.jumping_flight_time.unit
      )}`,
      jump1: {
        leftSymmetry: `${getFormattedData(
          segments.jumping_pronation_angle_foot_in.left_foot.avg[0]
        )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
        rightSymmetry: `${getFormattedData(
          segments.jumping_pronation_angle_foot_in.right_foot.avg[0]
        )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
        leftDistance: `${getFormattedData(
          segments.jumping_stride_length.left_foot.avg[0]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        rightDistance: `${getFormattedData(
          segments.jumping_stride_length.right_foot.avg[0]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        leftTime: `${getFormattedData(segments.jumping_stance_time.left_foot.avg[0])}${getSimplifiedUnit(
          aggregates.jumping_stance_time.unit
        )}`,
        rightTime: `${getFormattedData(segments.jumping_stance_time.right_foot.avg[0])}${getSimplifiedUnit(
          aggregates.jumping_stance_time.unit
        )}`,
        advancedLeftDistance: `${getFormattedData(
          segments.jumping_stride_length.left_foot.avg[0]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        advancedRightDistance: `${getFormattedData(
          segments.jumping_stride_length.right_foot.avg[0]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        advancedLeftTime: `${getFormattedData(
          segments.jumping_flight_time.left_foot.avg[0]
        )}${getSimplifiedUnit(aggregates.jumping_flight_time.unit)}`,
        advancedRightTime: `${getFormattedData(
          segments.jumping_flight_time.right_foot.avg[0]
        )}${getSimplifiedUnit(aggregates.jumping_flight_time.unit)}`,
        advancedLeftCompareTime: `${getFormattedData(
          segments.jumping_stance_time.left_foot.avg[0]
        )}${getSimplifiedUnit(aggregates.jumping_stance_time.unit)}`,
        advancedRightCompareTime: `${getFormattedData(
          segments.jumping_stance_time.right_foot.avg[0]
        )}${getSimplifiedUnit(aggregates.jumping_stance_time.unit)}`,
        advancedCompareTimeDiff: `${getFormattedData(
          segments.jumping_stance_time.global.diff_R_L[0]
        )}${getSimplifiedUnit(aggregates.jumping_stance_time.unit)}`,
        advancedCompareTimeDiffSide: segments.jumping_stance_time.global.side[0],
        heightDiff: `${getFormattedData(
          segments.jumping_stride_length.global.diff_R_L[0]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        timeDiff: `${getFormattedData(segments.jumping_flight_time.global.diff_R_L[0])}${getSimplifiedUnit(
          aggregates.jumping_flight_time.unit
        )}`,
        heightSideDiff: segments.jumping_stride_length.global.side[0],
        timeSideDiff: segments.jumping_flight_time.global.side[0]
      },
      jump2: {
        leftSymmetry: `${getFormattedData(
          segments.jumping_pronation_angle_foot_in.left_foot.avg[1]
        )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
        rightSymmetry: `${getFormattedData(
          segments.jumping_pronation_angle_foot_in.right_foot.avg[1]
        )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
        leftDistance: `${getFormattedData(
          segments.jumping_stride_length.left_foot.avg[1]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        rightDistance: `${getFormattedData(
          segments.jumping_stride_length.right_foot.avg[1]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        leftTime: `${getFormattedData(segments.jumping_stance_time.left_foot.avg[1])}${getSimplifiedUnit(
          aggregates.jumping_stance_time.unit
        )}`,
        rightTime: `${getFormattedData(segments.jumping_stance_time.right_foot.avg[1])}${getSimplifiedUnit(
          aggregates.jumping_stance_time.unit
        )}`,
        advancedLeftDistance: `${getFormattedData(
          segments.jumping_stride_length.left_foot.avg[1]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        advancedRightDistance: `${getFormattedData(
          segments.jumping_stride_length.right_foot.avg[1]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        advancedLeftTime: `${getFormattedData(
          segments.jumping_flight_time.left_foot.avg[1]
        )}${getSimplifiedUnit(aggregates.jumping_flight_time.unit)}`,
        advancedRightTime: `${getFormattedData(
          segments.jumping_flight_time.right_foot.avg[1]
        )}${getSimplifiedUnit(aggregates.jumping_flight_time.unit)}`,
        advancedLeftCompareTime: `${getFormattedData(
          segments.jumping_stance_time.left_foot.avg[1]
        )}${getSimplifiedUnit(aggregates.jumping_stance_time.unit)}`,
        advancedRightCompareTime: `${getFormattedData(
          segments.jumping_stance_time.right_foot.avg[1]
        )}${getSimplifiedUnit(aggregates.jumping_stance_time.unit)}`,
        advancedCompareTimeDiff: `${getFormattedData(
          segments.jumping_stance_time.global.diff_R_L[1]
        )}${getSimplifiedUnit(aggregates.jumping_stance_time.unit)}`,
        advancedCompareTimeDiffSide: segments.jumping_stance_time.global.side[1],
        heightDiff: `${getFormattedData(
          segments.jumping_stride_length.global.diff_R_L[1]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        timeDiff: `${getFormattedData(segments.jumping_flight_time.global.diff_R_L[1])}${getSimplifiedUnit(
          aggregates.jumping_flight_time.unit
        )}`,
        heightSideDiff: segments.jumping_stride_length.global.side[1],
        timeSideDiff: segments.jumping_flight_time.global.side[1]
      },
      jump3: {
        leftSymmetry: `${getFormattedData(
          segments.jumping_pronation_angle_foot_in.left_foot.avg[2]
        )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
        rightSymmetry: `${getFormattedData(
          segments.jumping_pronation_angle_foot_in.right_foot.avg[2]
        )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
        leftDistance: `${getFormattedData(
          segments.jumping_stride_length.left_foot.avg[2]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        rightDistance: `${getFormattedData(
          segments.jumping_stride_length.right_foot.avg[2]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        leftTime: `${getFormattedData(segments.jumping_stance_time.left_foot.avg[2])}${getSimplifiedUnit(
          aggregates.jumping_stance_time.unit
        )}`,
        rightTime: `${getFormattedData(segments.jumping_stance_time.right_foot.avg[2])}${getSimplifiedUnit(
          aggregates.jumping_stance_time.unit
        )}`,
        advancedLeftDistance: `${getFormattedData(
          segments.jumping_stride_length.left_foot.avg[2]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        advancedRightDistance: `${getFormattedData(
          segments.jumping_stride_length.right_foot.avg[2]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        advancedLeftTime: `${getFormattedData(
          segments.jumping_flight_time.left_foot.avg[2]
        )}${getSimplifiedUnit(aggregates.jumping_flight_time.unit)}`,
        advancedRightTime: `${getFormattedData(
          segments.jumping_flight_time.right_foot.avg[2]
        )}${getSimplifiedUnit(aggregates.jumping_flight_time.unit)}`,
        advancedLeftCompareTime: `${getFormattedData(
          segments.jumping_stance_time.left_foot.avg[2]
        )}${getSimplifiedUnit(aggregates.jumping_stance_time.unit)}`,
        advancedRightCompareTime: `${getFormattedData(
          segments.jumping_stance_time.right_foot.avg[2]
        )}${getSimplifiedUnit(aggregates.jumping_stance_time.unit)}`,
        advancedCompareTimeDiff: `${getFormattedData(
          segments.jumping_stance_time.global.diff_R_L[2]
        )}${getSimplifiedUnit(aggregates.jumping_stance_time.unit)}`,
        advancedCompareTimeDiffSide: segments.jumping_stance_time.global.side[2],
        heightDiff: `${getFormattedData(
          segments.jumping_stride_length.global.diff_R_L[2]
        )}${getSimplifiedUnit(aggregates.jumping_stride_length.unit)}`,
        timeDiff: `${getFormattedData(segments.jumping_flight_time.global.diff_R_L[2])}${getSimplifiedUnit(
          aggregates.jumping_flight_time.unit
        )}`,
        heightSideDiff: segments.jumping_stride_length.global.side[2],
        timeSideDiff: segments.jumping_flight_time.global.side[2]
      }
    };
  };

  const getTripleHopGeneralParametersSimplifiedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-landing',
        data: [
          {
            leftData: getFormattedData(aggregates.jumping_plantar_flexion_angle_foot_in.left_foot.avg),
            rightData: getFormattedData(aggregates.jumping_plantar_flexion_angle_foot_in.right_foot.avg),
            diffData: getFormattedData(aggregates.jumping_plantar_flexion_angle_foot_in.global.avg_diff_R_L),
            sideDiff: getFormattedData(aggregates.jumping_plantar_flexion_angle_foot_in.global.side),
            unit: aggregates.jumping_plantar_flexion_angle_foot_in.unit
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            leftData: getFormattedData(aggregates.jumping_pronation_angle_foot_in.left_foot.avg),
            rightData: getFormattedData(aggregates.jumping_pronation_angle_foot_in.right_foot.avg),
            diffData: getFormattedData(aggregates.jumping_pronation_angle_foot_in.global.avg_diff_R_L),
            sideDiff: getFormattedData(aggregates.jumping_pronation_angle_foot_in.global.side),
            unit: aggregates.jumping_pronation_angle_foot_in.unit
          }
        ]
      },
      {
        title: 'take-off-velocity',
        data: [
          {
            leftData: getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot.avg
            ),
            rightData: getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot.avg
            ),
            diffData: getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.avg_diff_R_L
            ),
            sideDiff: getFormattedData(aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side),
            unit: aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot.avg
            ),
            rightData: getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot.avg
            ),
            diffData: getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.global.avg_diff_R_L
            ),
            sideDiff: getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
            ),
            unit: aggregates.jumping_pronation_angle_amplitude_contact_phase.unit
          },
          {
            leftData: getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.left_foot.avg
            ),
            rightData: getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.right_foot.avg
            ),
            diffData: getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.global.avg_diff_R_L
            ),
            sideDiff: getFormattedData(aggregates.jumping_pronation_angular_speed_contact_phase.global.side),
            unit: aggregates.jumping_pronation_angular_speed_contact_phase.unit
          }
        ]
      }
    ];
  };

  const getTripleHopGeneralParametersAdvancedData = (aggregates: any, segments: any) => {
    return [
      {
        title: 'flexion-angle-at-landing',
        data: [
          {
            jump1LeftData: getFormattedData(segments.jumping_plantar_flexion_angle_foot_in.left_foot.avg[0]),
            jump1DataDiff: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_in.global.diff_R_L[0]
            ),
            jump1DataDiffSide: segments.jumping_plantar_flexion_angle_foot_in.global.side[0],
            jump2LeftData: getFormattedData(segments.jumping_plantar_flexion_angle_foot_in.left_foot.avg[1]),
            jump2DataDiff: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_in.global.diff_R_L[1]
            ),
            jump2DataDiffSide: segments.jumping_plantar_flexion_angle_foot_in.global.side[1],
            jump3LeftData: getFormattedData(segments.jumping_plantar_flexion_angle_foot_in.left_foot.avg[2]),
            jump3DataDiff: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_in.global.diff_R_L[2]
            ),
            jump3DataDiffSide: segments.jumping_plantar_flexion_angle_foot_in.global.side[2],
            jump1RightData: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_in.right_foot.avg[0]
            ),
            jump2RightData: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_in.right_foot.avg[1]
            ),
            jump3RightData: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_in.right_foot.avg[2]
            ),
            unit: aggregates.jumping_plantar_flexion_angle_foot_in.unit
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            jump1LeftData: getFormattedData(
              segments.jumping_pronation_angle_amplitude_contact_phase.left_foot.avg[0]
            ),
            jump1DataDiff: getFormattedData(
              segments.jumping_pronation_angle_amplitude_contact_phase.global.diff_R_L[0]
            ),
            jump1DataDiffSide: segments.jumping_pronation_angle_amplitude_contact_phase.global.side[0],
            jump2LeftData: getFormattedData(
              segments.jumping_pronation_angle_amplitude_contact_phase.left_foot.avg[1]
            ),
            jump2DataDiff: getFormattedData(
              segments.jumping_pronation_angle_amplitude_contact_phase.global.diff_R_L[1]
            ),
            jump2DataDiffSide: segments.jumping_pronation_angle_amplitude_contact_phase.global.side[1],
            jump3LeftData: getFormattedData(
              segments.jumping_pronation_angle_amplitude_contact_phase.left_foot.avg[2]
            ),
            jump3DataDiff: getFormattedData(
              segments.jumping_pronation_angle_amplitude_contact_phase.global.diff_R_L[2]
            ),
            jump3DataDiffSide: segments.jumping_pronation_angle_amplitude_contact_phase.global.side[2],
            jump1RightData: getFormattedData(
              segments.jumping_pronation_angle_amplitude_contact_phase.right_foot.avg[0]
            ),
            jump2RightData: getFormattedData(
              segments.jumping_pronation_angle_amplitude_contact_phase.right_foot.avg[1]
            ),
            jump3RightData: getFormattedData(
              segments.jumping_pronation_angle_amplitude_contact_phase.right_foot.avg[2]
            ),
            unit: aggregates.jumping_pronation_angle_amplitude_contact_phase.unit
          },
          {
            jump1LeftData: getFormattedData(
              segments.jumping_pronation_angular_speed_contact_phase.left_foot.avg[0]
            ),
            jump1DataDiff: getFormattedData(
              segments.jumping_pronation_angular_speed_contact_phase.global.diff_R_L[0]
            ),
            jump1DataDiffSide: segments.jumping_pronation_angular_speed_contact_phase.global.side[0],
            jump2LeftData: getFormattedData(
              segments.jumping_pronation_angular_speed_contact_phase.left_foot.avg[1]
            ),
            jump2DataDiff: getFormattedData(
              segments.jumping_pronation_angular_speed_contact_phase.global.diff_R_L[1]
            ),
            jump2DataDiffSide: segments.jumping_pronation_angular_speed_contact_phase.global.side[1],
            jump3LeftData: getFormattedData(
              segments.jumping_pronation_angular_speed_contact_phase.left_foot.avg[2]
            ),
            jump3DataDiff: getFormattedData(
              segments.jumping_pronation_angular_speed_contact_phase.global.diff_R_L[2]
            ),
            jump3DataDiffSide: segments.jumping_pronation_angular_speed_contact_phase.global.side[2],
            jump1RightData: getFormattedData(
              segments.jumping_pronation_angular_speed_contact_phase.right_foot.avg[0]
            ),
            jump2RightData: getFormattedData(
              segments.jumping_pronation_angular_speed_contact_phase.right_foot.avg[1]
            ),
            jump3RightData: getFormattedData(
              segments.jumping_pronation_angular_speed_contact_phase.right_foot.avg[2]
            ),
            unit: aggregates.jumping_pronation_angular_speed_contact_phase.unit
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            jump1LeftData: getFormattedData(segments.jumping_pronation_angle_foot_in.left_foot.avg[0]),
            jump1DataDiff: getFormattedData(segments.jumping_pronation_angle_foot_in.global.diff_R_L[0]),
            jump1DataDiffSide: segments.jumping_pronation_angle_foot_in.global.side[0],
            jump2LeftData: getFormattedData(segments.jumping_pronation_angle_foot_in.left_foot.avg[1]),
            jump2DataDiff: getFormattedData(segments.jumping_pronation_angle_foot_in.global.diff_R_L[1]),
            jump2DataDiffSide: segments.jumping_pronation_angle_foot_in.global.side[1],
            jump3LeftData: getFormattedData(segments.jumping_pronation_angle_foot_in.left_foot.avg[2]),
            jump3DataDiff: getFormattedData(segments.jumping_pronation_angle_foot_in.global.diff_R_L[2]),
            jump3DataDiffSide: segments.jumping_pronation_angle_foot_in.global.side[2],
            jump1RightData: getFormattedData(segments.jumping_pronation_angle_foot_in.right_foot.avg[0]),
            jump2RightData: getFormattedData(segments.jumping_pronation_angle_foot_in.right_foot.avg[1]),
            jump3RightData: getFormattedData(segments.jumping_pronation_angle_foot_in.right_foot.avg[2]),
            unit: aggregates.jumping_pronation_angle_foot_in.unit
          }
        ]
      },
      {
        title: 'rotation-around-z',
        data: [
          {
            jump1LeftData: getFormattedData(segments.jumping_relative_heading_angle.left_foot.avg[0]),
            jump1DataDiff: getFormattedData(segments.jumping_relative_heading_angle.global.diff_R_L[0]),
            jump1DataDiffSide: segments.jumping_relative_heading_angle.global.side[0],
            jump2LeftData: getFormattedData(segments.jumping_relative_heading_angle.left_foot.avg[1]),
            jump2DataDiff: getFormattedData(segments.jumping_relative_heading_angle.global.diff_R_L[1]),
            jump2DataDiffSide: segments.jumping_relative_heading_angle.global.side[1],
            jump3LeftData: getFormattedData(segments.jumping_relative_heading_angle.left_foot.avg[2]),
            jump3DataDiff: getFormattedData(segments.jumping_relative_heading_angle.global.diff_R_L[2]),
            jump3DataDiffSide: segments.jumping_relative_heading_angle.global.side[2],
            jump1RightData: getFormattedData(segments.jumping_relative_heading_angle.right_foot.avg[0]),
            jump2RightData: getFormattedData(segments.jumping_relative_heading_angle.right_foot.avg[1]),
            jump3RightData: getFormattedData(segments.jumping_relative_heading_angle.right_foot.avg[2]),
            unit: aggregates.jumping_relative_heading_angle.unit
          }
        ]
      },
      {
        title: 'max-acceleration-at-landing',
        data: [
          {
            jump1LeftData: getFormattedData(segments.jumping_acceleration_foot_in.left_foot.avg[0]),
            jump1DataDiff: getFormattedData(segments.jumping_acceleration_foot_in.global.diff_R_L[0]),
            jump1DataDiffSide: segments.jumping_acceleration_foot_in.global.side[0],
            jump2LeftData: getFormattedData(segments.jumping_acceleration_foot_in.left_foot.avg[1]),
            jump2DataDiff: getFormattedData(segments.jumping_acceleration_foot_in.global.diff_R_L[1]),
            jump2DataDiffSide: segments.jumping_acceleration_foot_in.global.side[1],
            jump3LeftData: getFormattedData(segments.jumping_acceleration_foot_in.left_foot.avg[2]),
            jump3DataDiff: getFormattedData(segments.jumping_acceleration_foot_in.global.diff_R_L[2]),
            jump3DataDiffSide: segments.jumping_acceleration_foot_in.global.side[2],
            jump1RightData: getFormattedData(segments.jumping_acceleration_foot_in.right_foot.avg[0]),
            jump2RightData: getFormattedData(segments.jumping_acceleration_foot_in.right_foot.avg[1]),
            jump3RightData: getFormattedData(segments.jumping_acceleration_foot_in.right_foot.avg[2]),
            unit: aggregates.jumping_acceleration_foot_in.unit
          }
        ]
      },
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            jump1LeftData: getFormattedData(segments.jumping_plantar_flexion_angle_foot_out.left_foot.avg[0]),
            jump1DataDiff: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_out.global.diff_R_L[0]
            ),
            jump1DataDiffSide: segments.jumping_plantar_flexion_angle_foot_out.global.side[0],
            jump2LeftData: getFormattedData(segments.jumping_plantar_flexion_angle_foot_out.left_foot.avg[1]),
            jump2DataDiff: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_out.global.diff_R_L[1]
            ),
            jump2DataDiffSide: segments.jumping_plantar_flexion_angle_foot_out.global.side[1],
            jump3LeftData: getFormattedData(segments.jumping_plantar_flexion_angle_foot_out.left_foot.avg[2]),
            jump3DataDiff: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_out.global.diff_R_L[2]
            ),
            jump3DataDiffSide: segments.jumping_plantar_flexion_angle_foot_out.global.side[2],
            jump1RightData: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_out.right_foot.avg[0]
            ),
            jump2RightData: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_out.right_foot.avg[1]
            ),
            jump3RightData: getFormattedData(
              segments.jumping_plantar_flexion_angle_foot_out.right_foot.avg[2]
            ),
            unit: aggregates.jumping_plantar_flexion_angle_foot_out.unit
          }
        ]
      },
      {
        title: 'take-off-velocity',
        data: [
          {
            jump1LeftData: getFormattedData(
              segments.jumping_plantar_flexion_angular_speed_foot_out.left_foot.avg[0]
            ),
            jump1DataDiff: getFormattedData(
              segments.jumping_plantar_flexion_angular_speed_foot_out.global.diff_R_L[0]
            ),
            jump1DataDiffSide: segments.jumping_plantar_flexion_angular_speed_foot_out.global.side[0],
            jump2LeftData: getFormattedData(
              segments.jumping_plantar_flexion_angular_speed_foot_out.left_foot.avg[1]
            ),
            jump2DataDiff: getFormattedData(
              segments.jumping_plantar_flexion_angular_speed_foot_out.global.diff_R_L[1]
            ),
            jump2DataDiffSide: segments.jumping_plantar_flexion_angular_speed_foot_out.global.side[1],
            jump3LeftData: getFormattedData(
              segments.jumping_plantar_flexion_angular_speed_foot_out.left_foot.avg[2]
            ),
            jump3DataDiff: getFormattedData(
              segments.jumping_plantar_flexion_angular_speed_foot_out.global.diff_R_L[2]
            ),
            jump3DataDiffSide: segments.jumping_plantar_flexion_angular_speed_foot_out.global.side[2],
            jump1RightData: getFormattedData(
              segments.jumping_plantar_flexion_angular_speed_foot_out.right_foot.avg[0]
            ),
            jump2RightData: getFormattedData(
              segments.jumping_plantar_flexion_angular_speed_foot_out.right_foot.avg[1]
            ),
            jump3RightData: getFormattedData(
              segments.jumping_plantar_flexion_angular_speed_foot_out.right_foot.avg[2]
            ),
            unit: aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit
          }
        ]
      }
    ];
  };

  const getCMJGeneralParametersSimplifiedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_out.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_out.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_out.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_out.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_out.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_out.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_out.global.side
          }
        ]
      },
      {
        title: 'take-off-velocity',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            sideDiff: aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
          },
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            sideDiff: aggregates.jumping_pronation_angular_speed_contact_phase.global.side
          }
        ]
      }
    ];
  };

  const getCMJGeneralParametersAdvancedData = (aggregates: any) => {
    return [
      ...getCMJGeneralParametersSimplifiedData(aggregates),
      {
        title: 'max-acceleration-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_acceleration_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_acceleration_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_acceleration_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_acceleration_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_acceleration_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_acceleration_foot_in.unit)}`,
            sideDiff: aggregates.jumping_acceleration_foot_in.global.side
          }
        ]
      },
      {
        title: 'rotation-around-z',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_relative_heading_angle.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_relative_heading_angle.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_relative_heading_angle.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_relative_heading_angle.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_relative_heading_angle.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_relative_heading_angle.unit)}`,
            sideDiff: aggregates.jumping_relative_heading_angle.global.side
          }
        ]
      },
      {
        title: 'flexion-angle-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            sideDiff: aggregates.jumping_pronation_angle_foot_in.global.side
          }
        ]
      }
    ];
  };

  const getSingleHopGeneralParametersSimplifiedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            sideDiff: aggregates.jumping_pronation_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'take-off-velocity',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            sideDiff: aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
          },
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            sideDiff: aggregates.jumping_pronation_angular_speed_contact_phase.global.side
          }
        ]
      }
    ];
  };

  const getSingleHopGeneralParametersAdvancedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'jump-height-foot',
        data: [
          {
            leftData: `${getFormattedData(aggregates.jumping_foot_height.left_foot.avg)}${getSimplifiedUnit(
              aggregates.jumping_foot_height.unit
            )}`,
            rightData: `${getFormattedData(aggregates.jumping_foot_height.right_foot.avg)}${getSimplifiedUnit(
              aggregates.jumping_foot_height.unit
            )}`,
            diffData: `${getFormattedData(
              aggregates.jumping_foot_height.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_foot_height.unit)}`,
            sideDiff: aggregates.jumping_foot_height.global.side
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            sideDiff: aggregates.jumping_pronation_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'max-acceleration-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_acceleration_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_acceleration_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_acceleration_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_acceleration_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_acceleration_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_acceleration_foot_in.unit)}`,
            sideDiff: aggregates.jumping_acceleration_foot_in.global.side
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            sideDiff: aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
          },
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            sideDiff: aggregates.jumping_pronation_angular_speed_contact_phase.global.side
          }
        ]
      },
      {
        title: 'rotation-around-z',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_relative_heading_angle.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_relative_heading_angle.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_relative_heading_angle.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_relative_heading_angle.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_relative_heading_angle.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_relative_heading_angle.unit)}`,
            sideDiff: aggregates.jumping_relative_heading_angle.global.side
          }
        ]
      },
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_out.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_out.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_out.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_out.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_out.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_out.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_out.global.side
          }
        ]
      },
      {
        title: 'ankle-velocity-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      }
    ];
  };

  const getSideHopGeneralParametersSimplifiedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            sideDiff: aggregates.jumping_pronation_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'take-off-velocity',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            sideDiff: aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
          },
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            sideDiff: aggregates.jumping_pronation_angular_speed_contact_phase.global.side
          }
        ]
      }
    ];
  };

  const getSideHopGeneralParametersAdvancedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_in.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'jump-height-foot',
        data: [
          {
            leftData: `${getFormattedData(aggregates.jumping_foot_height.left_foot.avg)}${getSimplifiedUnit(
              aggregates.jumping_foot_height.unit
            )}`,
            rightData: `${getFormattedData(aggregates.jumping_foot_height.right_foot.avg)}${getSimplifiedUnit(
              aggregates.jumping_foot_height.unit
            )}`,
            diffData: `${getFormattedData(
              aggregates.jumping_foot_height.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_foot_height.unit)}`,
            sideDiff: aggregates.jumping_foot_height.global.side
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angle_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_foot_in.unit)}`,
            sideDiff: aggregates.jumping_pronation_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'max-acceleration-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_acceleration_foot_in.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_acceleration_foot_in.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_acceleration_foot_in.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_acceleration_foot_in.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_acceleration_foot_in.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_acceleration_foot_in.unit)}`,
            sideDiff: aggregates.jumping_acceleration_foot_in.global.side
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angle_amplitude_contact_phase.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase.unit)}`,
            sideDiff: aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
          },
          {
            leftData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_pronation_angular_speed_contact_phase.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_pronation_angular_speed_contact_phase.unit)}`,
            sideDiff: aggregates.jumping_pronation_angular_speed_contact_phase.global.side
          }
        ]
      },
      {
        title: 'rotation-around-z',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_relative_heading_angle.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_relative_heading_angle.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_relative_heading_angle.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_relative_heading_angle.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_relative_heading_angle.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_relative_heading_angle.unit)}`,
            sideDiff: aggregates.jumping_relative_heading_angle.global.side
          }
        ]
      },
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_out.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_out.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_out.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_out.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angle_foot_out.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angle_foot_out.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_out.global.side
          }
        ]
      },
      {
        title: 'ankle-velocity-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            rightData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot.avg
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            diffData: `${getFormattedData(
              aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.avg_diff_R_L
            )}${getSimplifiedUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out.unit)}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      }
    ];
  };

  const getSideHopCadenceEvolutionChartOptions = (segments: any) => {
    const { theme, lineType, markerType } = useChart();

    const chartLeftData = segments.jumping_stride_cadence.left_foot.avg;
    const chartRightData = segments.jumping_stride_cadence.right_foot.avg;

    const series = computed(() => {
      return [
        {
          id: 'cadence',
          name: i18n.t('commons.standards.left-feet'),
          data: chartRightData,
          color: theme.colors.leftFoot,
          type: lineType,
          marker: {
            symbol: markerType
          },
          zIndex: 1
        },
        {
          id: 'cadence',
          name: i18n.t('commons.standards.right-feet'),
          data: chartLeftData,
          color: theme.colors.rightFoot,
          type: lineType,
          marker: {
            symbol: markerType
          },
          zIndex: 1
        }
      ];
    });

    return computed(() => {
      return {
        chart: {
          animation: true,
          height: 200
        },
        title: {
          text: 'commons.standards.cadence',
          style: {
            fontSize: '0'
          }
        },
        xAxis: {
          labels: {
            formatter() {
              return '';
            }
          }
        },
        yAxis: {
          opposite: true,
          labels: {
            align: 'left',
            format: `{value}`
          },
          gridLineDashStyle: 'longdash',
          title: {
            text: '',
            margin: 20
          },
          maxZoom: 100
        },
        plotOptions: {
          areaspline: {
            lineWidth: 3,
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, 'rgba(186,204,215,0.4)'],
                [1, 'rgba(255,255,255,0.1)']
              ]
            }
          },
          series: {
            enableMouseTracking: true,
            animation: true
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          distance: 50,
          crosshairs: true,
          followPointer: true
        },
        series: series.value
      };
    });
  };

  const getActiveWidgets = (mode: TRehabMode) => {
    switch (mode) {
      case 'triple-hop':
        return ['total-distance-symmetry', 'total-distance'];
      case 'CMJ':
        return [];
      case 'single-hop':
        return ['total-distance-symmetry', 'total-distance'];
      case 'side-hop':
        return ['symmetry', 'jump-count'];
      default:
        return [];
    }
  };

  return {
    getSimplifiedUnit,
    getFormattedData,
    getMode,
    getActiveWidgets,
    getTripleHopJumpProfileData,
    getTripleHopGeneralParametersSimplifiedData,
    getTripleHopGeneralParametersAdvancedData,
    getCMJGeneralParametersSimplifiedData,
    getCMJGeneralParametersAdvancedData,
    getSingleHopGeneralParametersAdvancedData,
    getSingleHopGeneralParametersSimplifiedData,
    getSideHopGeneralParametersSimplifiedData,
    getSideHopGeneralParametersAdvancedData,
    getSideHopCadenceEvolutionChartOptions
  };
}
