// Import vendors ----------------------------------------------------------------------------------
import { computed, reactive, ref, watch } from '@vue/composition-api';
import { merge, isString } from 'lodash';
// Import types ------------------------------------------------------------------------------------
import type { DataOptions } from 'vuetify';
import type { RequestModule } from '@/plugins/podocore/modules/request/request.module';
// -------------------------------------------------------------------------------------------------

export function useDataTable(
  requester: ReturnType<RequestModule['useRequest'] | RequestModule['useAuthenticatedRequest']>,
  vuetifyOptions?: Partial<DataOptions>,
  useImproved?: true
) {
  /**
   * Vuetify options
   */
  const vuetifyOptionsWithDefault = reactive<DataOptions>(
    merge(
      {},
      {
        page: 1,
        itemsPerPage: 5,
        sortBy: ['left.footSize'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
      },
      vuetifyOptions
    ) as DataOptions
  );

  /**
   * Request options
   */
  const requestOptions = ref<any>({});

  /**
   * Request query parameters
   */
  const requestQueryParameters = computed(() => ({
    limit: vuetifyOptionsWithDefault.itemsPerPage,
    page: vuetifyOptionsWithDefault.page,
    sort: vuetifyOptionsWithDefault.sortBy.map((name, index) => {
      return [name, vuetifyOptionsWithDefault.sortDesc[index] ? 1 : -1];
    }),
    useImproved
  }));

  /**
   * Search
   */
  const search = ref('');

  /**
   * Delay before the request was sent after an update of "search"
   */
  const searchDelay = reactive({
    config: 400,
    timeout: undefined as any
  });

  /**
   * "isPending" flag with search delay included
   */
  const isDelayedPending = ref(false);

  const hasBeenLoadedAtLeastOnce = ref(false);

  /**
   * Sanitized data
   */
  const sanitizedData = computed(() => {
    return (
      requester.data.value ?? {
        docs: [],
        hasPrevious: false,
        hasNext: false,
        totalDocs: 0
      }
    );
  });

  /**
   * Request
   */
  function request() {
    requester.request(
      merge(
        {},
        {
          axios: {
            params: requestQueryParameters.value
          }
        },
        requestOptions.value
      )
    );
  }

  /**
   * Use this function instead of a "sync" modifier to avoid loss of reactivity
   */
  function updateOptions(options: DataOptions) {
    for (const option in options) {
      if (Object.prototype.hasOwnProperty.call(options, option)) {
        (vuetifyOptionsWithDefault as any)[option] = (options as any)[option];
      }
    }
  }

  watch(requester.isPending, (value) => {
    isDelayedPending.value = true;
    hasBeenLoadedAtLeastOnce.value = true;

    if (!value) {
      isDelayedPending.value = false;
    }
  });

  // Do a request when the "search" is updated
  watch(search, (value) => {
    // Clear timeout
    if (searchDelay.timeout) clearTimeout(searchDelay.timeout);

    isDelayedPending.value = true;

    if (isString(value) && value.length > 0) {
      searchDelay.timeout = setTimeout(() => {
        requestOptions.value = { axios: { params: { search: value.trim() } } };
        request();
      }, searchDelay.config);
    } else {
      requestOptions.value = {};
      request();
    }
  });

  // Do a request when a query parameter is updated
  watch(
    requestQueryParameters,
    () => {
      request();
    },
    { immediate: true }
  );

  return {
    // Values
    options: vuetifyOptionsWithDefault,
    search,
    sanitizedData,
    // Methods
    request,
    updateOptions,
    // Flags
    isDelayedPending,
    hasBeenLoadedAtLeastOnce
  };
}
