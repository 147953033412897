import { EValueTypeUnit } from './helped-chart-functions.helper';
import { EUnit } from '@/plugins/podocore/repositories/doctor.repository';
import { PatientGender } from '@/plugins/podocore/repositories/patients.repository';

export enum EFootSizeUnit {
  Eu = 'eu',
  Uk = 'uk',
  Us = 'us'
}

export type TUnitsByValueType = {
  [key in EValueTypeUnit]: {
    [EUnit.Imperial]: string;
    [EUnit.Metric]: string;
  };
};
export type TFootSizes = {
  value: number;
  [EFootSizeUnit.Eu]: number;
  [EFootSizeUnit.Uk]?: number;
  [EFootSizeUnit.Us]: TFootSizesByGender;
};
export type TFootSizesByGender = {
  [PatientGender.Male]: number[];
  [PatientGender.Female]: number[];
};

export function staticValues() {
  const walkingAngleAverage: any = {
    toesOff: {
      min: -7.5,
      max: -2.5
    },
    heelOff: {
      min: -12.5,
      max: -7.5
    },
    flatFoot: {
      min: -12.5,
      max: -7.5
    },
    heelStrike: {
      min: -17.5,
      max: -12.5
    }
  };
  const runningAngleAverage: any = {
    toesOff: {
      min: -6,
      max: -2
    },
    flatFoot: {
      min: -15,
      max: -5
    },
    heelStrike: {
      min: -7,
      max: 2
    }
  };
  const unitByValueType: TUnitsByValueType = {
    [EValueTypeUnit.Speed]: {
      [EUnit.Imperial]: 'mph',
      [EUnit.Metric]: 'km/h'
    },
    [EValueTypeUnit.Distance]: {
      [EUnit.Imperial]: 'yard',
      [EUnit.Metric]: 'm'
    },
    [EValueTypeUnit.Circumduction]: {
      [EUnit.Imperial]: 'in',
      [EUnit.Metric]: 'cm'
    },
    [EValueTypeUnit.Clearance]: {
      [EUnit.Imperial]: 'in',
      [EUnit.Metric]: 'cm'
    },
    [EValueTypeUnit.StrideLength]: {
      [EUnit.Imperial]: 'in',
      [EUnit.Metric]: 'm'
    }
  };
  const footSizesStandards: TFootSizes[] = [
    {
      value: 30,
      [EFootSizeUnit.Eu]: 30,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [12],
        [PatientGender.Female]: [12]
      }
    },
    {
      value: 31,
      [EFootSizeUnit.Eu]: 31,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [12.5],
        [PatientGender.Female]: [13]
      }
    },
    {
      value: 32,
      [EFootSizeUnit.Eu]: 32,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [13.5],
        [PatientGender.Female]: [1.5]
      }
    },
    {
      value: 33,
      [EFootSizeUnit.Eu]: 33,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [2],
        [PatientGender.Female]: [2.5]
      }
    },
    {
      value: 34,
      [EFootSizeUnit.Eu]: 34,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [3],
        [PatientGender.Female]: [3]
      }
    },
    {
      value: 35,
      [EFootSizeUnit.Eu]: 36,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [3.5],
        [PatientGender.Female]: [4]
      }
    },
    {
      value: 36,
      [EFootSizeUnit.Eu]: 35,
      [EFootSizeUnit.Uk]: 2.5,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [4.5],
        [PatientGender.Female]: [5]
      }
    },

    {
      value: 36,
      [EFootSizeUnit.Eu]: 36,
      [EFootSizeUnit.Uk]: 2.5,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [4.5],
        [PatientGender.Female]: [5.5]
      }
    },
    {
      value: 37,
      [EFootSizeUnit.Eu]: 37,
      [EFootSizeUnit.Uk]: 3.5,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [5, 5.5],
        [PatientGender.Female]: [6, 6.5]
      }
    },
    {
      value: 38,
      [EFootSizeUnit.Eu]: 38,
      [EFootSizeUnit.Uk]: 5,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [6],
        [PatientGender.Female]: [7]
      }
    },
    {
      value: 39,
      [EFootSizeUnit.Eu]: 39,
      [EFootSizeUnit.Uk]: 5.5,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [6.5, 7],
        [PatientGender.Female]: [7.5, 8]
      }
    },
    {
      value: 40,
      [EFootSizeUnit.Eu]: 40,
      [EFootSizeUnit.Uk]: 7.5,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [7.5],
        [PatientGender.Female]: [8.5]
      }
    },
    {
      value: 41,
      [EFootSizeUnit.Eu]: 41,
      [EFootSizeUnit.Uk]: 9,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [8, 8.5],
        [PatientGender.Female]: [9, 9.5]
      }
    },
    {
      value: 42,
      [EFootSizeUnit.Eu]: 42,
      [EFootSizeUnit.Uk]: 9.5,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [9],
        [PatientGender.Female]: [10]
      }
    },
    {
      value: 43,
      [EFootSizeUnit.Eu]: 43,
      [EFootSizeUnit.Uk]: 10.5,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [9.5, 10],
        [PatientGender.Female]: [10.5, 11]
      }
    },
    {
      value: 44,
      [EFootSizeUnit.Eu]: 44,
      [EFootSizeUnit.Uk]: 12,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [10.5],
        [PatientGender.Female]: [11.5]
      }
    },
    {
      value: 45,
      [EFootSizeUnit.Eu]: 45,
      [EFootSizeUnit.Uk]: 13,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [11, 11.5],
        [PatientGender.Female]: [12, 12.5]
      }
    },
    {
      value: 46,
      [EFootSizeUnit.Eu]: 46,
      [EFootSizeUnit.Uk]: 14.5,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [12],
        [PatientGender.Female]: [13]
      }
    },
    {
      value: 47,
      [EFootSizeUnit.Eu]: 47,
      [EFootSizeUnit.Uk]: 14.5,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [12.5, 13],
        [PatientGender.Female]: [13.5, 14]
      }
    },
    {
      value: 48,
      [EFootSizeUnit.Eu]: 48,
      [EFootSizeUnit.Uk]: 16,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [13.5],
        [PatientGender.Female]: [14.5]
      }
    },
    {
      value: 49,
      [EFootSizeUnit.Eu]: 49,
      [EFootSizeUnit.Uk]: 17.5,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [14, 14.5],
        [PatientGender.Female]: [15, 15.5]
      }
    },
    {
      value: 50,
      [EFootSizeUnit.Eu]: 50,
      [EFootSizeUnit.Uk]: 19,
      [EFootSizeUnit.Us]: {
        [PatientGender.Male]: [15],
        [PatientGender.Female]: [16]
      }
    }
  ];
  const scenarioKeyToAnalysisType = {
    walking_embedded: 'walk',
    running_embedded: 'run'
  };

  return {
    walkingAngleAverage,
    runningAngleAverage,
    unitByValueType,
    footSizesStandards,
    scenarioKeyToAnalysisType
  };
}
