















































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, watch, nextTick, onMounted } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import PatientPathologyForm from '@/components/patient/PatientPathologyForm.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------
// TODO: check ce fichier
export default defineComponent({
  name: 'DialogPatientPathology',
  components: {
    CompositeDialog,
    AlertError,
    PatientPathologyForm
  },
  props: {
    patientPathologiesCuids: {
      type: Array
    }
  },
  setup(properties, { emit }) {
    const dialogId: DialogId = 'patient-pathology';
    const rDialog = ref<any>(null);

    const requestModule = usePodocoreModule('request');

    let pathologyRequest = ref<any>(null);
    let patientPatchRequest = ref<any>(null);

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (value) {
            pathologyRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/pathologies`
            );

            pathologyRequest.value.request();
          } else {
            pathologyRequest.value.cancel();

            nextTick(() => {
              pathologyRequest.value.clear();

              pathologyRequest.value = null;
            });
          }
        }
      );
    });

    const availalePathologies = computed(() => {
      const data = pathologyRequest.value.data;
      data.pathologies = data.pathologies.filter(
        (p: any) => !properties.patientPathologiesCuids?.includes(p.cuid)
      );
      return data;
    });

    function setPatientPathology(data: any) {
      emit('submit', { ...data, meta: rDialog.value.meta });
      closeDialog();
    }

    function closeDialog() {
      rDialog.value.close();
    }
    function submitForm() {
      rDialog.value.$children[0].$children[0].$children[0].$children[3].submit();
    }

    const isMounted = computed(() => {
      return pathologyRequest.value;
    });

    const isDisabled = computed(() => {
      return !pathologyRequest.value || pathologyRequest.value?.isPending;
    });

    return {
      rDialog,
      dialogId,
      pathologyRequest,
      patientPatchRequest,
      availalePathologies,
      // Methods
      submitForm,
      setPatientPathology,
      closeDialog,
      // Flags
      isMounted,
      isDisabled
    };
  }
});
