import i18n from '@/plugins/i18n';
import moment from 'moment';
import Highcharts from 'highcharts';

import { rounded } from '@/helpers/conversion.helper';
import { useUnit } from '@/utils/unit.utils';
import { useParameterUnits, EUnitKey } from '@/utils/parameter-units.utils';

import { isNil } from 'lodash';

export type TTitle = {
  text: string;
  plural?: number;
};

export enum EVariationType {
  UP = 'up',
  DOWN = 'down',
  NEUTRAL = 'neutral'
}
export enum EVariationStatus {
  GOOD = 'good',
  BAD = 'bad',
  NEUTRAL = 'neutral'
}

type TVariations =
  | {
      left: TVariation;
      right: TVariation;
    }
  | TVariation;

type TVariation = { status: EVariationStatus; type: EVariationType; value: number };

export enum EThemeColor {
  Chart1 = 'chart1',
  Chart2 = 'chart2',
  Chart3 = 'chart3',
  Chart4 = 'chart4',
  Chart5 = 'chart5',
  Chart6 = 'chart6',
  Left = 'leftFoot',
  Right = 'rightFoot'
}

export type TLineStyle = 'line' | 'spline';
export type TRangeStyle = 'arearange' | 'areasplinerange';

export function useChart(): any {
  const { currentUnit } = useUnit();
  const { getUnitSign } = useParameterUnits();

  const impCurrentUnit: boolean = currentUnit().isImperial.value;

  const sides = [{ left: 'left_foot' }, { right: 'right_foot' }];
  const theme = {
    colors: {
      [EThemeColor.Chart1]: '#ff8300',
      [EThemeColor.Chart2]: '#2962FD',
      [EThemeColor.Chart3]: '#6c5ce7',
      [EThemeColor.Chart4]: '#00b894',
      [EThemeColor.Chart5]: '#d42f68',
      [EThemeColor.Chart6]: '#0093dd',
      [EThemeColor.Left]: '#B298DC',
      [EThemeColor.Right]: '#00A0D1'
    }
  };
  const chartHeight = 350;
  const lineType: TLineStyle = 'spline';
  const rangeType: TRangeStyle = 'areasplinerange';
  const shapeOpacity = 0.1;
  const marker = {
    symbol: 'circle',
    lineWidth: 2,
    radius: 3,
    fillColor: '#fff'
  };

  function getVariationType(first: number, last: number): EVariationType {
    if (last === first) return EVariationType.NEUTRAL;
    return last < first ? EVariationType.DOWN : EVariationType.UP;
  }
  function getVariationStatus(value: number): EVariationStatus {
    if (value === 0) return EVariationStatus.NEUTRAL;
    return value <= 5 ? EVariationStatus.GOOD : EVariationStatus.BAD;
  }

  function getSideData(parameter: any | null | undefined, manageImp?: boolean) {
    const units = {
      avg: impCurrentUnit && manageImp ? 'imp_avg' : 'avg',
      std: impCurrentUnit && manageImp ? 'imp_std' : 'std'
    };

    const data: any = {};
    for (const side of sides) {
      const values = parameter ? parameter[Object.values(side)[0] as any] : { avg: [], std: [] };
      data[Object.keys(side)[0]] = {
        values: values[units.avg],
        ranges: getRange(values[units.avg], values[units.std])
      };
    }
    return data;
  }

  function getGlobalData(parameter: any | null | undefined, manageImp?: boolean) {
    const units = {
      avg: impCurrentUnit && manageImp ? 'imp_avg' : 'avg',
      std: impCurrentUnit && manageImp ? 'imp_std' : 'std'
    };

    const values = parameter ? parameter.global : { avg: [], std: [] };
    return {
      global: values[units.avg],
      ranges: getRange(values[units.avg], values[units.std])
    };
  }

  function getRange(avg: number[], std: number[]) {
    const ranges: [number | null, number | null][] = [];
    avg.forEach((value: any, index: number) => {
      ranges.push(isNil(value) ? [null, null] : [value - std[index], value + std[index]]);
    });
    return ranges;
  }

  function getNorms(min: number[], max: number[]) {
    const norms: [number, number][] = [];
    min.forEach((value: any, index: number) => {
      norms.push([min[index], max[index]]);
    });
    return norms;
  }

  function getSerieItemStructure(
    id: string,
    name: string | undefined,
    data: any,
    color: EThemeColor,
    isRange?: boolean,
    options?: any
  ) {
    return {
      id: isRange ? `${id}_range` : id,
      name,
      data,
      color: theme.colors[color],
      type: isRange ? rangeType : lineType,
      linkedTo: isRange ? id : undefined,
      opacity: isRange ? shapeOpacity : undefined,
      marker: {
        ...marker,
        lineColor: theme.colors[color],
        enabled: !isRange
      },
      ...options
    };
  }

  function getNormsStructure(id: string, norms: { min: number[]; max: number[] }) {
    return {
      id,
      name: i18n.tc('commons.standards.norm', 1),
      data: getNorms(norms.min, norms.max),
      fillOpacity: 1,
      lineWidth: 0,
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, 'rgba(51,204,48,0)'],
          [0.5, 'rgba(51,204,48,0.4)'],
          [1, 'rgba(51,204,48,0)']
        ]
      },
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, 'rgba(51,204,48,0)'],
          [0.5, 'rgba(51,204,48,0.15)'],
          [1, 'rgba(51,204,48,0)']
        ]
      },
      type: rangeType,
      linkedTo: id,
      showInLegend: true,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false
          }
        }
      },
      states: {
        hover: {
          lineWidth: 0,
          lineWidthPlus: 0
        }
      }
    };
  }

  function getSideDataVariations(data: {
    [key: string]: number[];
    left: number[];
    right: number[];
  }): TVariations {
    const variations: any = {};
    let side: any;

    for (side of ['left', 'right']) {
      const value = Math.abs(rounded(100 - (data[side][data[side].length - 1] * 100) / data[side][0]));
      variations[side] = {
        status: getVariationStatus(value),
        type: getVariationType(data[side][0], data[side][data[side].length - 1]),
        value
      };
    }

    return variations;
  }

  function getGlobalDataVariations(data: number[]): TVariation {
    const value = Math.abs(rounded(100 - (data[data.length - 1] * 100) / data[0])) || 0;
    return {
      status: getVariationStatus(value),
      type: getVariationType(data[0], data[data.length - 1]),
      value
    };
  }

  function evolutionChartStructure(
    title: string | TTitle,
    manifest: any,
    unit: EUnitKey,
    round: number,
    multipleLegend?: { groups: string[][]; getLegendGroup(id: string): void }
  ) {
    const titleText = typeof title === 'string' ? i18n.t(title) : i18n.tc(title.text, title.plural);

    return {
      chart: {
        style: {
          fontFamily: 'Montserrat'
        }
      },
      title: {
        text: titleText,
        style: {
          fontSize: 0,
          fontWeight: 600,
          margin: 0,
          color: '#fff'
        }
      },
      xAxis: {
        className: 'highcharts-color-0',
        title: {
          text: i18n.tc('commons.standards.acquisition', 2),
          margin: 10,
          style: {
            fontSize: '0.95em',
            fontWeight: 600,
            color: '#B4B6BB'
          }
        },
        allowDecimals: false,
        crosshair: {
          width: 40,
          color: 'rgba(206,214,217,0.2)'
        },
        labels: {
          enabled: false
        },
        tickLength: 0,
        lineColor: '#ededed',
        lineWidth: 2
      },
      yAxis: {
        title: {
          enabled: false
        },
        // plotBands: [
        //   {
        //     from: norms?.min[0] ?? 0,
        //     to: norms?.max[0] ?? 0,
        //     // color: 'rgba(51,204,48,0.1)'
        //     color: {
        //       linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        //       stops: [
        //         [0, 'rgba(51,204,48,0.02)'],
        //         [0.5, 'rgba(51,204,48,0.1)'],
        //         [1, 'rgba(51,204,48,0.02)']
        //       ]
        //     }
        //   }
        // ],
        labels: {
          padding: 0,
          formatter(): any {
            const that: any = this;
            if (that.isFirst) return getUnitSign(unit);
            return that.value;
          },
          style: {
            fontSize: '0.95em',
            fontWeight: 600,
            color: 'black'
          }
        },
        opposite: true
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick(event: any) {
              const _chart: any = Highcharts.charts.find(
                (x: any) => x && x.options.title!.text === titleText
              );
              const that: any = this;

              if (multipleLegend) {
                multipleLegend.getLegendGroup(that.userOptions.id);
                for (const group of multipleLegend.groups) {
                  if (group.includes(that.userOptions.id)) {
                    for (const item of group.filter((x: any) => x !== that.userOptions.id)) {
                      const serie = _chart!.series.find((x: any) => x.userOptions.id === item);
                      // @ts-ignore
                      _chart!.series[serie.index].update({
                        visible: true
                      });
                    }
                  } else {
                    for (const item of group) {
                      const serie = _chart!.series.find((x: any) => x.userOptions.id === item);
                      // @ts-ignore
                      _chart!.series[serie.index].update({
                        visible: false
                      });
                    }
                  }
                }
              }
            }
          }
        }
      },
      legend: {
        align: 'left',
        itemStyle: {
          fontSize: '12px',
          symbolHeight: 14
        }
      },
      tooltip: {
        shared: true,
        useHTML: true,
        followPointer: true,
        borderWidth: 2,
        backgroundColor: 'rgba(255,255,255, 0.97)',
        borderColor: '#f5f7fa',
        borderRadius: 10,
        shadow: false,
        formatter() {
          const that: any = this;
          // String buffer
          let s = '<tr><td></br>';
          // Iterate on the points
          for (const p of that.points!) {
            // Determinate if it's a range
            const isRange = /^Series \d+$/.test(p.series.name);
            // Populate string buffer
            if (isRange) {
              if (rounded(p.point.low) !== rounded(p.point.high)) {
                s += `<span style="margin-left: 10px; color: grey">(${rounded(
                  p.point.low!,
                  round
                )} ⁃ ${rounded(p.point.high!, round)})</span>`;
              }
              // Close the row
              s += '<tr><td>';
            } else if (p.series.name === i18n.tc('commons.standards.norm', 1)) {
              s += `<span style="color: ${p.color}; font-weight: bold">${p.series.name}</span> : `;
              s += `${rounded(p.point.low!, round)}  ⁃  ${rounded(p.point.high!, round)}`;
            } else if (p.series.name !== i18n.tc('commons.standards.norm', 1)) {
              s += `<span style="color: ${p.color}; font-weight: bold">${p.series.name}</span> : `;
              s += `<b>${rounded(p.point.y!, round)}</b> ${getUnitSign(unit)}`;
            }
          }

          return `<table><thead><tr><th style="font-size: 15px">${moment(
            manifest[that.points![0].point.x].createdAt
          ).format('LL')} : ${moment(manifest[that.points![0].point.x].createdAt).format(
            'HH:mm'
          )}</th></tr></thead><tbody>${s}</tbody></table>`;
        }
      }
    };
  }

  return {
    sides,
    theme,
    chartHeight,
    lineType,
    rangeType,
    shapeOpacity,
    markerType: 'circle',
    marker,
    evolutionChartStructure,
    getSideData,
    getGlobalData,
    getRange,
    getGlobalDataVariations,
    getSideDataVariations,
    getSerieItemStructure,
    getNormsStructure
  };
}
